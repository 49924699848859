import Image from 'next/image'

export default function Loading() {
  return (
    <div className="z-90 flex h-screen w-full items-center justify-center bg-white">
      <Image
        src="/assets/img/favicon.png"
        className="w-16 animate-bounce"
        width={100}
        height={100}
        alt="Loading"
      />
    </div>
  )
}
