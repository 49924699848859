'use client'

import '../globals.css'

import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { ReactNode, Suspense } from 'react'

import Footer from '@/components/Footer'

const DynamicNavbar = dynamic(() => import('@/components/Navbar'), {
  loading: () => <p>Loading...</p>,
})

import Image from 'next/image'
import Link from 'next/link'

import Loading from './loading'

export default function RootLayout({ children }: { children: ReactNode }) {
  const page = usePathname()
  const NoNavFooter = [
    '/modalidade-graduacao',
    '/modalidade-pos-graduacao',
    '/certificados',
  ]

  return (
    <>
      {NoNavFooter.includes(page) ||
      page.match(/certificados\/[a-zA-Z0-9]{1,100}/) ? (
        <>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </>
      ) : (
        <>
          <Suspense fallback={<Loading />}>
            <DynamicNavbar />
            {children}
            <Footer />
          </Suspense>
        </>
      )}
      <div className="fixed bottom-5 right-5 cursor-pointer">
        <Link href="/whatsapp">
          <Image
            width={50}
            height={50}
            src={'/assets/icon-whats.png'}
            className="transition-all delay-75 hover:scale-105 hover:animate-pulse "
            alt="Fale conosco pelo whatsapp"
          />
        </Link>
      </div>
    </>
  )
}
