import {
  faFacebook,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faGraduationCap,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'

export default function Footer() {
  return (
    <footer className=" bg-footer px-4 text-center text-sm text-neutral-content lg:px-16 lg:text-start">
      <div className="mx-auto grid w-full max-w-screen-2xl grid-cols-1 lg:grid-cols-2">
        <div className="py-5 lg:pb-5 lg:pr-4 lg:pt-10 lg:leading-5">
          <p className="mb-1 text-base font-bold text-white">FALE CONOSCO</p>
          <p className="mb-1 text-xs">
            <FontAwesomeIcon
              icon={faLocationDot}
              width={15}
              className="mr-3 inline"
            />
            <span>
              Av. Pres. Kennedy, 1693 - Nova Ribeirânia, Ribeirão Preto - SP,
              14096- 340
            </span>
          </p>
          <p className="mb-1 text-xs">
            <FontAwesomeIcon
              icon={faPhone}
              width={15}
              className="mr-3 inline"
            />
            <span>(16) 3617-1318</span>
          </p>
          <p className="mb-1 text-xs">
            <FontAwesomeIcon
              icon={faEnvelope}
              width={15}
              className="mr-3 inline"
            />
            <span>atendimento@faculdademetropolitana.edu.br</span>
          </p>
          <p className="mb-5 mt-4 text-xs">
            <span className="block text-base font-bold text-white">
              CONHEÇA NOSSOS POLOS
            </span>
            <FontAwesomeIcon
              icon={faGraduationCap}
              width={20}
              className="mr-2 inline"
            />
            <Link
              href="polos"
              className="cursor-pointer"
              aria-label="Ver Polos"
            >
              {' '}
              <span>Clique aqui para saber mais</span>
            </Link>
          </p>
          <p className="mb-1 text-xs">
            <span className="block text-base font-bold text-white">
              TRABALHE CONOSCO
            </span>
            <FontAwesomeIcon
              icon={faEnvelope}
              width={15}
              className="mr-3 inline"
            />
            <Link
              href="mailto:trabalheconosco@faculdademetropolitana.edu.br"
              title="Trabalhe conosco"
            >
              <span>trabalheconosco@faculdademetropolitana.edu.br</span>
            </Link>
          </p>
          <p className="mb-5 mt-4 text-xs">
            <span className="block text-base font-bold text-white">
              ACERVO ACADÊMICO
            </span>
            <FontAwesomeIcon
              icon={faGraduationCap}
              width={20}
              className="mr-2 inline"
            />
            <Link
              href="/diploma-digital"
              className="cursor-pointer"
              aria-label="Polos"
            >
              {' '}
              Diploma Digital
            </Link>
            <br />
            <FontAwesomeIcon
              icon={faGraduationCap}
              width={20}
              className="mr-2 inline"
            />
            <Link
              href="/historico-digital"
              className="cursor-pointer"
              aria-label="Polos"
            >
              {' '}
              Histórico Digital
            </Link>
            <br />
            <FontAwesomeIcon
              icon={faGraduationCap}
              width={20}
              className="mr-2 inline"
            />
            <Link
              href="/curriculo-digital"
              className="cursor-pointer"
              aria-label="Polos"
            >
              {' '}
              Currículo Digital
            </Link>
            <br />
          </p>
        </div>

        <div className="py-5 lg:pb-5 lg:pt-10 lg:leading-4">
          <div className="w-full lg:hidden">
            <Link
              href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.7768867404425!2d-47.77000218506449!3d-21.20102038590523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9b8abe1a1dd7b%3A0xa21a5445bf4a67a4!2sAv.%20Pres.%20Kennedy%2C%201693%20-%20Nova%20Ribeir%C3%A2nia%2C%20Ribeir%C3%A3o%20Preto%20-%20SP%2C%2014096-340!5e0!3m2!1sen!2sbr!4v1633094754518!5m2!1sen!2sbr"
              aria-label="Google Maps"
            >
              CLique aqui para ser redirecionado ao Google Maps
            </Link>
          </div>
          <div className="hidden w-full lg:block">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.7768867404425!2d-47.77000218506449!3d-21.20102038590523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9b8abe1a1dd7b%3A0xa21a5445bf4a67a4!2sAv.%20Pres.%20Kennedy%2C%201693%20-%20Nova%20Ribeir%C3%A2nia%2C%20Ribeir%C3%A3o%20Preto%20-%20SP%2C%2014096-340!5e0!3m2!1sen!2sbr!4v1633094754518!5m2!1sen!2sbr"
              width="100%"
              height="140"
              loading="lazy"
              title="Nossa localização"
            ></iframe>
          </div>

          <p className="mt-6 justify-normal">
            Credenciada pelo Ministério da Educação (MEC) via portaria nº 842 de
            30 de setembro de 2014 e possui o credenciamento para a oferta de
            Educação a Distância via Portaria de credenciamento EAD n° 1.956 de
            07/11/2019.
          </p>
          <br />
          <div className="mt-4">
            <FontAwesomeIcon
              icon={faGraduationCap}
              width={20}
              className="mr-2 inline"
            />
            <Link
              href="/autenticar-certificado"
              className="cursor-pointer"
              aria-label="Polos"
            >
              {' '}
              Autenticar certificado de eventos online
            </Link>
          </div>
        </div>

        <div className="py-5 lg:pb-10 lg:pt-5 lg:leading-5">
          <p className="mb-1 pt-3 text-xs">
            <Image
              src="/assets/img/logo-footer.png"
              className="mr-3 inline w-5"
              width={100}
              height={100}
              alt="Faculdade Metropolitana"
            />
            <span>Copyright © 2025 FAMEESP</span>
          </p>
        </div>

        <div className="py-5 lg:pb-10 lg:pt-5 lg:leading-4">
          <Link
            href="https://www.reclameaqui.com.br/empresa/faculdade-de-negocios-metropolitana/?utm_source=referral&utm_medium=embbed&utm_campaign=reputacao&utm_term=horizontal"
            target="new"
            aria-label="Reclame Aqui"
          >
            <Image
              src="/assets/img/ra-footer.jpeg"
              className="inline w-32 rounded-md"
              width={100}
              height={100}
              alt="RA"
              placeholder="empty"
            />
          </Link>

          <div className="inline-block pl-3 pt-3 lg:float-right lg:pr-4">
            <Link
              href="https://www.facebook.com/FaculdadeMetropolitanaRP/"
              target="new"
              aria-label="Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                width={20}
                className="mr-5 inline"
              />
            </Link>
            <Link
              href="https://www.instagram.com/facmetropolitana/"
              target="new"
              aria-label="Instagram"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                width={20}
                className="mr-5 inline"
              />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UC13HQzuqOgpCXltQCg-OvNQ"
              target="new"
              aria-label="Youtube"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                width={20}
                className="mr-5 inline"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
